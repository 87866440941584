













































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { ListItem, ListItemPart, Overlay } from '../components';
import I18n from '../I18n';
import { Comp, Org, Version } from '../types';


const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleVersionSelection');


@Component({
    components: {
        ListItem,
        ListItemPart,
        Overlay,
    },
})
export default class SingleVersionSelection extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Version | null;

    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: Version | null) => {
            if (value?.id !== this.localValue?.id) {
                this.localValue = value;
            }
        });

        // noinspection JSUnusedLocalSymbols
        this.$watch('org', (org: Org) => {
            this.reloadVersions();
        });
        // endregion


        // region Data
        this.$watch('localValue', (localValue: Version | null) => {
            if (localValue?.id !== this.value?.id) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.localValue?.id !== this.value?.id) {
            this.localValue = this.value;
        }
        this.reloadVersions();
    }
    // endregion


    // region Utils
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Data
    private loading = false;

    // noinspection JSMismatchedCollectionQueryUpdate
    private versions: Array<Version> = [];

    private localValue: Version | null = null;

    private get orgText(): string {
        const org = this.org;
        const type = org.type;
        if (org.type === 'GU') {
            let title: string;
            if (this.i18n.isKazakh) {
                title = org.gu.nameKk ?? org.gu.nameRu;
            } else {
                title = org.gu.nameRu;
            }

            return `${org.gu.code} - ${title}`;
        } else if (org.type === 'KGKP') {
            let title: string;
            if (this.i18n.isKazakh) {
                title = org.kgkp.nameKk ?? org.kgkp.bin;
            } else {
                title = org.kgkp.nameRu;
            }

            return `${org.kgkp.bin} - ${title}`;
        } else {
            throw new Error(`Cannot process organization with unknown type \"${type}\"`);
        }
    }

    private reloadVersions() {
        if (this.loading) {
            console.error('Cannot reload versions - another loading is running');
            return;
        }

        const orgCode = this.org.org.code;
        const orgText = this.orgText;
        this.loading = true;

        Ax<Array<Version>>(
            {
                url: `/api/budget/staffing_table/version/list?org-code=${encodeURIComponent(orgCode)}`,
            },
            (data) => {
                this.versions = data;

                const value = data.find((item) => {
                    return (item.id === this.localValue?.id);
                });
                this.localValue = (value ?? null);
            },
            (error) => { this.toast('danger', this.i18n.translate('cannot_load_versions', [orgText]), error.toString()); },
            () => { this.loading = false; }
        );
    }

    private getListItemVariant(version: Version): Comp.ColorVariant | null {
        if (this.localValue?.id === version.id) {
            return 'primary';
        } else {
            return null;
        }
    }

    private onVersionClick(version: Version) { this.localValue = version; }
    // endregion
}
