




























import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Org, Version } from '../types';
import I18n from '../I18n';
import SingleVersionSelection from './SingleVersionSelection.vue';
import SelectionFieldRow from '../components/SelectionFieldRow.vue';


const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleVersionField');


@Component({
    components: {
        SelectionFieldRow,
        SingleVersionSelection,
    },
})
export default class SingleVersionField extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Version | null;

    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: Version | null) => {
            if (this.localValue?.id !== value?.id) {
                this.localValue = value;
            }
        });
        // endregion


        // region Data
        this.$watch('localValue', (localValue: Version | null) => {
            if (this.value?.id !== localValue?.id) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.localValue?.id !== this.value?.id) {
            this.localValue = this.value;
        }
    }
    // endregion


    // region Utils
    private i18n = i18n;
    // endregion


    // region Modal
    private modalVisible: boolean = false;

    private modalValue: Version | null = null;

    private get modalOkDisabled(): boolean { return (this.modalValue === null); }

    private onModalOk() {
        this.localValue = this.modalValue;
    }
    // endregion


    // region Data
    private localValue: Version | null = null;

    private get localValueText(): string | null {
        const localValue = this.localValue;
        if (localValue === null) return null;

        return this.i18n.translate('version_text_template', [localValue.id, localValue.year]) + ': «' + (localValue?.title ?? '') + '»';
    }

    private onFieldClick() {
        this.modalValue = this.localValue;
        this.modalVisible = true;
    }
    // endregion
}
